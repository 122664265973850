import { CloudFrameDefaultHeader } from "@cloudframe/header";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const location = useLocation()
  const navigate = useNavigate();
  const isResultRoute = location.pathname === '/result';
  return (
    <div>
      <CloudFrameDefaultHeader
      title="File Comparator Utility"
      headerConfig={[]}
      linkComponent={Link}
      backLink={{
        label: "Back to AppStore",
        onClick() {
          window.parent.postMessage('reload', '*');
        },
        href : "",
        // : "http://utilities.cloudframe.com",
        style: {
          background: "transparent",
          color: "white",
          marginRight: "1.5rem",
          borderRadius: "2px",
          paddingTop: ".25rem",
          paddingBottom: ".25rem",
          fontWeight: "500",
          fontSize: "15px",
        },
      }}
    />
    
    </div>
  );
};

export { Header };
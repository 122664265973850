import { useDispatch, useSelector } from "react-redux";
// import ReactDiffViewer from "react-diff-viewer-continued";
import { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Spin, Button, Modal } from "antd";
import {
  updateAsciiHexCFResult,
  updateComparisonResult,
  updateDiffOnly,
  updateJavaContent,
  updateMainFrameContent,
  updateMaxRecordLength,
} from "../../store/Store";
import "./ComparisionResult.css";
import Offset from "../offset/offset";
import { ReactDiffViewerStylesOverride } from "react-diff-viewer-continued";
import ReactDOMServer from "react-dom/server";
import { convert2Hexa } from "../conversion/convert2Ascii";

const defaultStyle: ReactDiffViewerStylesOverride = {
  line: {
    wordBreak: "break-word",
    marginRight: "10px",
  },
  gutter: {
    backgroundColor: "#1677ff",
    "&:hover": {
      backgroundColor: "#1677ff",
    },
    maxWidth : "20px",
    pre: {
      opacity: 1,
    },
  },
  emptyGutter : {
    "&:after": {
      content: "'  '",
    }
  },
  lineNumber: {
    color: "white",
    "&:after": {
      content: "'  >'",
    },
  },
  contentText: {
    fontWeight: "600",
  },
  wordDiff: {
    display: "inline-flex",
    whiteSpace: "pre-wrap",
    alignItems: "center",
  },
};

const offsetStyle: ReactDiffViewerStylesOverride = {
  diffContainer: {
    overflowX: "auto",
    display: "flex",
    "& pre": { whiteSpace: "pre" , marginLeft : "-2px" },
    maxWidth: 1400,
    whiteSpace: "pre-wrap",
    overflowWrap : "break-word",  },
  gutter: {
    backgroundColor: "#1677ff",
    "&:hover": {
      backgroundColor: "#1677ff",
    },
    pre: {
      opacity: 1,
    },
  },
  lineNumber: {
    color: "white",
    "&:after": {
      content: "'  >'",
    },
  },
  contentText: {
    fontWeight: "600",
  },
  wordDiff: {
    whiteSpace:"pre-wrap",
    wordWrap: "break-word",
    overflowWrap : "break-word",
    alignItems: "center",
    maxWidth:"1700px",
    marginLeft: "-4px",
  },
};

const ReactDiffViewer = lazy(() => import("react-diff-viewer-continued"));
const ComparisonResult = () => {
  const javaContent = useSelector((state: any) => state.javaFileContent);
  const recordLength = useSelector((state: any) => state.recordLength);
  const mainFrameContent = useSelector(
    (state: any) => state.mainFrameFileContent
  );
  const javaFile = useSelector((state: any) => state.javaFileName);
  const mainframeFile = useSelector((state: any) => state.mainframeFileName);
  const splitView = useSelector((state: any) => state.splitView);
  const diffOnly = useSelector((state: any) => state.diffOnly);
  const offset = useSelector((state: any) => state.offset);
  const selectedStyles = offset ? offsetStyle : defaultStyle;
  const context = useSelector((state: any) => state.context);
  const [modalOpen, setModalOpen] = useState(true);
  const [chunksLoaded, setChunksLoaded] = useState(1);
  const [showDiff, setShowDiff] = useState(true);
  const CHUNK_SIZE = 3 * 1024 * 1024;
  const diffViewerRef = useRef(null);
  const CFResult = useSelector((state: any) => state.CFResult);
  const MFResult = useSelector((state: any) => state.MFResult);
  const [resultCF, setResultCF] = useState([...CFResult]);
  const [resultMF, setResultMF] = useState([...MFResult]);
  const [offsetCF, setOffsetCF] = useState([...CFResult]);
  const [offsetMF, setOffsetMF] = useState([...MFResult]);
  const CFResultOG = useSelector((state: any) => state.CFResult);
  const MFResultOG = useSelector((state: any) => state.MFResult);
  const asciiHexCF = useSelector((state: any) => state.asciiHexCF);
  const asciiHexMF = useSelector((state: any) => state.asciiHexMF);
  const [outputCF,setOutputCF] = useState(javaContent);
  const [offsetOPCF, setOffsetOPCF] = useState(javaContent);  
  const [outputMF,setOutputMF] = useState(mainFrameContent);
  const [offsetOPMF, setOffsetOPMF] = useState(mainFrameContent);
  // const [ReactDiffViewer, setReactDiffViewer] = useState(null);
  const hasJavaData = chunksLoaded * CHUNK_SIZE < javaContent.length;
  const hasMFData = chunksLoaded * CHUNK_SIZE < mainFrameContent.length;
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [clickedNumericValues, setClickedNumericValues] = useState<number[]>(
    []
  );
  const [clickedNumericValuesOff, setClickedNumericValuesOff] = useState<number[]>(
    []
  );
  const dispatch = useDispatch();
  const differencesExist = javaContent !== mainFrameContent;
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(updateComparisonResult(false));
  }, []);

  const exportDifferences = () => {
    const differencesHTML = generateDifferencesHTML(
      javaContent,
      mainFrameContent,
      javaFile,
      mainframeFile
    );
    downloadHTML(differencesHTML, javaFile, mainframeFile);
  };

  const fetchMoreData = () => {
    if (hasJavaData || hasMFData) setChunksLoaded(chunksLoaded + 1);
  };
  useEffect(() => {
    // setReactDiffViewer(DiffViewer)
    setShowDiff(false);
    setChunksLoaded(1);
    setShowDiff(true);
  }, [splitView, diffOnly, context]);
  useEffect(() => {
    const handleScroll = () => {
      const diffViewer = document.getElementById("diffContainer");
      const difference =
        (diffViewer as any)?.scrollHeight - (diffViewer as any)?.scrollTop;
      const roundedDifference = Math.floor(difference);
      const isBottom =
        roundedDifference === (diffViewer as any)?.clientHeight ||
        roundedDifference - 1 === (diffViewer as any)?.clientHeight ||
        roundedDifference + 1 === (diffViewer as any)?.clientHeight;
      setIsScrolledToBottom(isBottom);
    };

    const diffViewer = document.getElementById("diffContainer");
    (diffViewer as any)?.addEventListener("scroll", handleScroll);

    return () => {
      (diffViewer as any)?.removeEventListener("scroll", handleScroll);
    };
  }, [chunksLoaded, diffOnly]);

  useEffect(() => {
    if (isScrolledToBottom) {
      fetchMoreData();
    }
  }, [isScrolledToBottom]);
  const generateDifferencesHTML = (
    oldValue: string,
    newValue: string,
    javaFile: any,
    mainframeFile: any
  ) => {
    const javaLines = oldValue.split("\n");
    
    const mainFrameLines = newValue.split("\n");
    const maxLength = Math.max(javaLines.length, mainFrameLines.length);

    for (let i = javaLines.length; i < maxLength; i++) {
      javaLines.push("");
    }

    for (let i = mainFrameLines.length; i < maxLength; i++) {
      mainFrameLines.push("");
    }

    const diffLines = [];

    for (let i = 0; i < maxLength; i++) {
      const javaLine = javaLines[i];
      const javaHexLine : string = asciiHexCF.get(javaLines[i])
      const mainFrameLine = mainFrameLines[i];
      const mainFrameHexLine = asciiHexMF.get(mainFrameLines[i])
      console.log(mainFrameHexLine)

      if (javaLine !== mainFrameLine) {
        const diffCharsJava = javaLine
          .split("")
          .map((char, index) => {
            return char !== mainFrameLine[index]
              ? `<span class="java-highlight">${char}</span>`
              : char;
          })
          .join("");

          const diffCharsJavaHex = javaHexLine?.split("")
          .map((char, index) => {
            return char !== mainFrameHexLine[index]
              ? `<span class="java-highlight">${char}</span>`
              : char;
          })
          .join("");
          
        const diffCharsMainframe = mainFrameLine
          .split("")
          .map((char, index) => {
            return char !== javaLine[index]
              ? `<span class="mainframe-highlight">${char}</span>`
              : char;
          })
          .join("");

          const diffCharsMainFrameHex = javaHexLine?.split("")
          .map((char, index) => {
            return char !== mainFrameHexLine[index]
              ? `<span class="mainframe-highlight">${char}</span>`
              : char;
          })
          .join("");

        diffLines.push({
          line: i + 1,
          javaLine: diffCharsJava,
          mainFrameLine: diffCharsMainframe,
          javaHexLine: diffCharsJavaHex,
          mainFrameHexLine: diffCharsMainFrameHex,
        });
      }
    }

    const tableRows = diffLines.map(
      (
        { line, javaLine, mainFrameLine, javaHexLine, mainFrameHexLine },
        index
      ) => {
        return `
          <tr key="${index}">
            <td>${line}</td>
            <td>${javaLine}</td>
            <td>${javaHexLine}</td>
            <td>${mainFrameLine}</td>
            <td>${mainFrameHexLine}</td>
          </tr>
        `;
      }
    );

    const style = `
      <style>
        h2 {
          font-family: 'Barlow', sans-serif;
          text-align: center;
        }
        .diff-table {
          width: 100%;
          height: 40%;
          border-collapse: collapse;
          border: 1px solid gray;
          margin-right: 40px;
          margin-top: 40px;
        }
        th {
          font-size: 20px;
          font-family: 'Barlow', sans-serif;
          padding: 5px;
          border: 1px solid gray;
        }
        td {
          font-family: 'Barlow', sans-serif;
          font-size: 14px;
          padding: 8px;
          word-break: break-all;
          border: 1px solid gray;
        }
        tr {
          background-color: white;
        }
        .java-highlight {
          background-color: #ffcccc; 
        }
        .mainframe-highlight {
          background-color: #ccffcc; 
        }
      </style>
    `;

    const tableHTML = `
      <html>
        <head>${style}</head>
        <body>
          <h2>Differences</h2>
          <table class="diff-table">
            <thead>
              <tr>
                <th>Line Number</th>
                <th>${javaFile} (ASCII)</th>
                <th>${javaFile} (Hex)</th>
                <th>${mainframeFile} (ASCII)</th>
                <th>${mainframeFile} (Hex)</th>
              </tr>
            </thead>
            <tbody>
              ${tableRows.join("")}
            </tbody>
          </table>
        </body>
      </html>
    `;

    return tableHTML;
  };
  useEffect(()=>{
    if(offset){
      dispatch(updateJavaContent(offsetOPCF))
      dispatch(updateMainFrameContent(offsetOPMF))
    } else {
      dispatch(updateJavaContent(outputCF))
      dispatch(updateMainFrameContent(outputMF))
    }
  },[dispatch,offset])
  const downloadHTML = (
    html: string,
    javaFile: string,
    mainframeFile: string
  ) => {
    const blob = new Blob([html], { type: "text/html" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    const combinedFileName = `${javaFile}_vs_${mainframeFile}_differences.html`;
    a.href = url;
    a.download = combinedFileName;
    a.click();
    URL.revokeObjectURL(url);
  };

  const displayEntireView = () => {
    setModalOpen(false);
    dispatch(updateDiffOnly(false));
  };
  var rLength :any;
  const length = localStorage.getItem("maxRecordLength")
  if(length) {
    rLength = parseInt(length,10)
    }
  const onLineClick = (lineId: string) => {
    const numericPart = lineId.split("-")[1];
    const numericValue = parseInt(numericPart, 10);

    const index = clickedNumericValues.indexOf(numericValue);
    const indexOff = clickedNumericValuesOff.indexOf(numericValue);
      if (offset) { 
        if (indexOff != -1) {
          const updatedArray = [...clickedNumericValuesOff];
          updatedArray.splice(index, 1);
          setClickedNumericValuesOff(updatedArray);
          if (offsetCF[numericValue - 1]) {
            offsetCF[numericValue - 1] = CFResult[numericValue - 1];
            let cFOutput = "";
            for (var j = 0; j < resultCF.length - 1; j++) {
              cFOutput += offsetCF[j] + "\n";
            }
            cFOutput += offsetCF[j];
            setOffsetOPCF(cFOutput)
            setOffsetCF([...offsetCF])
            dispatch(updateJavaContent(cFOutput));
          }
          if (offsetMF[numericValue - 1]) {
            offsetMF[numericValue - 1] = MFResult[numericValue - 1];
            let mFOutput = "";
            for (var j = 0; j < offsetMF.length - 1; j++) {
              mFOutput += offsetMF[j] + "\n";
            }
            mFOutput += offsetMF[j];
            setOffsetOPMF(mFOutput)
            setOffsetMF([...offsetMF])
            dispatch(updateMainFrameContent(mFOutput));
          }
        } else {
          setClickedNumericValuesOff((prevArray) => [...prevArray, numericValue]);
          const hexCF = asciiHexCF.get(offsetCF[numericValue - 1]);
          const hexMF = asciiHexMF.get(offsetMF[numericValue - 1]);
            if (offsetCF[numericValue - 1]) {    
                console.log("offset here: ")
                const test =  offsetCF[numericValue - 1].length;
                offsetCF[numericValue - 1] =  hexCF;;
              let cFOutput = "";
              for (var j = 0; j < offsetCF.length - 1; j++) {
                cFOutput += offsetCF[j] + "\n";
              }
              cFOutput += offsetCF[j];
              setOffsetOPCF(cFOutput)
              setOffsetCF([...offsetCF])
              dispatch(updateJavaContent(cFOutput));
            }
            if (offsetMF[numericValue - 1]) {
              const test =  offsetMF[numericValue - 1].length;
                offsetMF[numericValue - 1] =  hexMF;
              let mFOutput = "";
              for (var j = 0; j < offsetMF.length - 1; j++) {
                mFOutput += offsetMF[j] + "\n";
              }
              mFOutput += offsetMF[j];
              setOffsetOPMF(mFOutput)
              setOffsetMF([...offsetMF])
              dispatch(updateMainFrameContent(mFOutput));      
              } 
            } 
        } else {
            if (index != -1) {
              const updatedArray = [...clickedNumericValues];
              updatedArray.splice(index, 1);
              setClickedNumericValues(updatedArray);
              if (resultCF[numericValue - 1]) {
                resultCF[numericValue - 1] = CFResult[numericValue - 1];
                let cFOutput = "";
                for (var j = 0; j < resultCF.length - 1; j++) {
                  cFOutput += resultCF[j] + "\n";
                }
                cFOutput += resultCF[j];
                setOutputCF(cFOutput)
                setResultCF([...resultCF])
                dispatch(updateJavaContent(cFOutput));
              }
              if (resultMF[numericValue - 1]) {
                resultMF[numericValue - 1] = MFResult[numericValue - 1];
                let mFOutput = "";
                for (var j = 0; j < resultMF.length - 1; j++) {
                  mFOutput += resultMF[j] + "\n";
                }
                mFOutput += resultMF[j];
                setOutputMF(mFOutput)
                setResultMF([...resultMF])
                dispatch(updateMainFrameContent(mFOutput));
              }
            } else {
              setClickedNumericValues((prevArray) => [...prevArray, numericValue]);
              const hexCF = asciiHexCF.get(resultCF[numericValue - 1]);
              const hexMF = asciiHexMF.get(resultMF[numericValue - 1]);
              const checkCF = 74 - resultCF[numericValue - 1].length;
            console.log("not offset here: ")
            if (resultCF[numericValue - 1]) {   
              resultCF[numericValue - 1] =
                resultCF[numericValue - 1] +
                " ".repeat(checkCF < 0 ? 80 - checkCF : checkCF) +
                hexCF;
              let cFOutput = "";
              for (var j = 0; j < resultCF.length - 1; j++) {
                cFOutput += resultCF[j] + "\n";
              }
              cFOutput += resultCF[j];
              setOutputCF(cFOutput)
              setResultCF([...resultCF])
              dispatch(updateJavaContent(cFOutput));
            }
            if (resultMF[numericValue - 1]) {
              const checkMF = 74 - resultMF[numericValue - 1].length;
              resultMF[numericValue - 1] =
                resultMF[numericValue - 1] +
                " ".repeat(checkMF < 0 ? 80 - checkMF : checkMF) +
                hexMF;
              let mFOutput = "";
              for (var j = 0; j < resultMF.length - 1; j++) {
                mFOutput += resultMF[j] + "\n";
              }
              mFOutput += resultMF[j];
              setOutputMF(mFOutput)
              setResultMF([...resultMF])
              dispatch(updateMainFrameContent(mFOutput));
            }
        }  
      
  }
  };
  return (
    <div>
      {(!diffOnly || differencesExist) && (
        <div>
          <div
            style={{ height: "100vh", overflowX: "auto" }}
            id="diffContainer"
            title="Click on Gutter to show Hex Value"
          >
            <Suspense
              fallback={
                <Spin
                  size="large"
                  tip="Loading the result.."
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    textAlign: "center",
                  }}
                />
              }
            >
              <div>
                {/* <Spin tip="Loading the result.." spinning={loading}> */}
                {showDiff && (
                  <ReactDiffViewer
                    ref={diffViewerRef}
                    // key={diffViewerKey}
                    oldValue={
                      diffOnly
                        ? mainFrameContent
                        : mainFrameContent.slice(0, chunksLoaded * CHUNK_SIZE)
                    }
                    newValue={
                      diffOnly
                        ? javaContent
                        : javaContent.slice(0, chunksLoaded * CHUNK_SIZE)
                    }
                    splitView={splitView}
                    showDiffOnly={diffOnly}
                    extraLinesSurroundingDiff={context}
                    leftTitle={offset ? <Offset /> : mainframeFile}
                    rightTitle={javaFile}
                    styles={selectedStyles}
                    onLineNumberClick={onLineClick}
                  />
                )}
              </div>
              {/* </Spin> */}
            </Suspense>
          </div>

          <div style={{ textAlign: "center" }}>
            <Button
              type="primary"
              onClick={exportDifferences}
              disabled={!differencesExist}
              style={{ marginTop: "20px", height: "34px" }}
            >
              Export Differences
            </Button>
          </div>
        </div>
      )}
      {!differencesExist && diffOnly && (
        <Modal
          title="There are no differences"
          centered
          open={modalOpen}
          onCancel={() => setModalOpen(false)}
          onOk={displayEntireView}
        >
          <p>
            The selected files{" "}
            <span style={{ fontWeight: "bold" }}>{mainframeFile}</span> and{" "}
            <span style={{ fontWeight: "bold" }}>{javaFile}</span> do not have
            any differences. Do you want to see the complete file?
          </p>
        </Modal>
      )}
    </div>
  );
};

export default ComparisonResult;

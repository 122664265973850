import { CloudFramePageFooter } from '@cloudframe/page-footer'

const Footer = () => {
  return (
    <footer style={{position:"relative",display:"bottom"}}>
    <CloudFramePageFooter id="footer" testId="footerTestId">
    {[
        <h3>CloudFrame</h3>,
        <p>Copyright @ 2023</p>,                        
    ]}
    </CloudFramePageFooter>
    </footer>
  );
};

export { Footer };
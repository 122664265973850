import { useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { updateComparisonResult, updateContext, updateContextCheck, updateDiffOnly, updateSplitView, updateOffestView } from "../../store/Store";
import { Spin, Switch } from "antd";
import React from "react";

const AddConfg : React.FC = () => {
    const [loading,setLoading] = useState(false);
    const dispatch = useDispatch();
    const diffOnly = useSelector((state:any)=>state.diffOnly)
    const splitView = useSelector((state:any)=>state.splitView)
    const contextCheck = useSelector((state:any)=>state.contextcheck);
    const offset = useSelector((state:any)=>state.offset)
  const handleDifferenceOnChange = (checked:boolean) => {
        // dispatch(updateLoading(true))
        dispatch(updateDiffOnly(checked))
  }
  const handleSwitchOnChange = (checked: boolean) => {
    if (checked) {
      dispatch(updateOffestView(false));
    }
    // dispatch(updateLoading(true));
    dispatch(updateSplitView(checked));
  };

  const handleOffsetViewChange = (checked: boolean) => {
    if (checked) {
      dispatch(updateSplitView(false));
    } else {
      dispatch(updateSplitView(true))
    }
    dispatch(updateOffestView(checked));
    // dispatch(updateLoading(true));
  };
  
  const handleContextOnChange = (checked:boolean) => {
        dispatch(updateContextCheck(checked))
        if(checked){
            // dispatch(updateLoading(true))
            dispatch(updateContext(5))
        } else{
            // dispatch(updateLoading(true))
            dispatch(updateContext(0))
        }
  }  
    return (
        <div>
            <h2 className="main-heading">Additional Configurations</h2>
            <Spin tip="Comparing the files.." spinning={loading} size="large" >
            <div className="sub-sub-container">
            <div style={{display:"flex"}}>
                <div style={{display:"flex",alignItems:"center",justifyContent:"center",marginLeft:"2%"}}>
                    <h5 style={{ fontWeight:"600", fontSize:"16px"}}>Show Difference Only : </h5>
                    <Switch  checked = {diffOnly} onChange={handleDifferenceOnChange} style={{marginLeft:"50px"}}/> 
                    </div>
                {diffOnly && (<div  style={{display:"flex",alignItems:"center",justifyContent:"center",marginLeft:"8%"}}><h5 style={{ fontWeight:"600", fontSize:"16px"}}>Show Context : </h5><Switch  checked ={contextCheck} onChange={handleContextOnChange} style={{marginLeft:"60px"}}/></div>)}
                <div  style={{display:"flex",alignItems:"center",justifyContent:"center",marginLeft:"10%"}}><h5 style={{ fontWeight:"600", fontSize:"16px"}}>View Offset : </h5><Switch  checked ={offset} onChange={handleOffsetViewChange} style={{marginLeft:"60px"}}/></div>
            </div>
            </div>
            </Spin>
        </div>
    )
}
export default AddConfg;
import { Dropdown, MenuProps, Space } from "antd"
import { useDispatch, useSelector } from "react-redux";
import { DownOutlined } from '@ant-design/icons';
import { updateDisableNext, updateFileType, updateRecordLength } from "../../store/Store";
import "./FileType.css"
import { useEffect, useState } from "react";
import React from "react";
const items: MenuProps['items'] = [
    {
      key: '1',
      label:"Fixed Length File"
    },
    {
      key: '2',
      label:"Variable Length File"
    }
  ];  
const FileType: React.FC = ()=> {
    const fileType = useSelector((state:any)=>state.fileType);
    const recordLength = useSelector((state:any)=>state.recordLength)
    const dispatch = useDispatch();
    const [localFileType, setLocalFileType] = useState<string | null>("Fixed Length");
    const [localRecordLength, setLocalRecordLength] = useState<string>("");

    useEffect(() => {
      const storedFileType = sessionStorage.getItem("fileType");
      const storedRecordLength = sessionStorage.getItem("recordLength");
  
      if (storedFileType) {
        setLocalFileType(storedFileType);
      }
  
      if (storedRecordLength) {
        setLocalRecordLength(storedRecordLength);
      }

    }, []);

    useEffect(() => {
      const tabIdentifier = sessionStorage.getItem("tabIdentifier");
    
      if (!tabIdentifier) {
        sessionStorage.setItem("tabIdentifier", "uniqueValue");
      }
    
      const handleUnload = () => {
        setTimeout(() => {
          if (!sessionStorage.getItem("tabIdentifier")) {
            sessionStorage.removeItem("fileType");
            sessionStorage.removeItem("recordLength");
          }
        }, 0);
      };
    
      window.addEventListener("unload", handleUnload);
    
      return () => {
        window.removeEventListener("unload", handleUnload);
        sessionStorage.removeItem("tabIdentifier");
      };
    }, []);       
    
    useEffect(() => {
      if (localFileType) {
        sessionStorage.setItem("fileType", localFileType);
      }
  
      if (localRecordLength) {
        sessionStorage.setItem("recordLength", localRecordLength);
        const numericRecordLength = /^\d*\.?\d*$/.test(localRecordLength) ? parseInt(localRecordLength) : 0;
        dispatch(updateRecordLength(numericRecordLength));
        dispatch(updateDisableNext(false));
      } else if (localFileType === "Variable Length") {
        dispatch(updateDisableNext(false));
       } else {
        dispatch(updateDisableNext(true));
      } 
    }, [localFileType, localRecordLength, dispatch]);
    
    const onClick: MenuProps['onClick'] = ({ key }) => {
        if(key === "1") {
          setLocalFileType("Fixed Length")
          dispatch(updateFileType("Fixed Length"))
          dispatch(updateDisableNext(true))
        }
        else {
          setLocalFileType("Variable Length");
          dispatch(updateFileType("Variable Length"))
          dispatch(updateDisableNext(false))
        }
      };

    const handleRecordLengthChange = (event: any) => {
      const value = event.target.value;
      setLocalRecordLength(value); 
      if (value === "") {
        dispatch(updateDisableNext(true));
        sessionStorage.removeItem("recordLength");
      } else {
        const numericValue = /^\d*\.?\d*$/.test(value) ? parseInt(value) : 0;
        dispatch(updateDisableNext(false));
        dispatch(updateRecordLength(numericValue));
      }
    };

    return (
       
        <div>
        <h2 className="main-heading">Please Select FileType</h2>
        <div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "10px", marginTop:"50px"}}>
            <h5 style={{ margin: "0" , fontWeight:"600", fontSize:"16px",marginRight:"100px"}}>Please select the type of File:</h5>
            <Dropdown menu={{ items, onClick }}>
              <Space style={{ marginLeft: "120px", fontSize:"16px", color:"rgb(9, 97, 220)" }}>
              {localFileType || fileType}
                <DownOutlined />
              </Space>
            </Dropdown>
          </div>
          {localFileType === "Fixed Length" && (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <label><h5 style={{fontWeight:"600", fontSize:"16px"}}>Enter Record Length:</h5></label>
            <input 
              type="text" 
              style={{ height: "24px", marginLeft: "210px", color:"rgb(9, 97, 220)", border:"1px solid rgb(9, 97, 220)", padding:"5px",borderRadius:"3px", textAlign:"center"}}
              value={localRecordLength !== null ? localRecordLength : recordLength}
              onChange={handleRecordLengthChange} 
            />
          </div>)}
        </div>
      </div>
    )
}
export default FileType;
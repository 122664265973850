
let e2UtfLookup = new Array (
  0,1,2,3,156,9,134,127,151,141,142,11,12,13,14,15,16,17,18,19,157,10,8,135,24,25,146,143,28,29,30,31,128,129,130,131,132,133,23,27,136,137,138,139,140,5,6,7,144,145,22,147,148,149,150,4,152,153,154,155,20,21,158,26,32,160,226,228,224,225,227,229,231,241,162,46,60,40,43,124,38,233,234,235,232,237,238,239,236,223,33,36,42,41,59,94,45,47,194,196,192,193,195,197,199,209,166,44,37,95,62,63,248,201,202,203,200,205,206,207,204,96,58,35,64,39,61,34,216,97,98,99,100,101,102,103,104,105,171,187,240,253,254,177,176,106,107,108,109,110,111,112,113,114,170,186,230,184,198,164,181,126,115,116,117,118,119,120,121,122,161,191,208,91,222,174,172,163,165,183,169,167,182,188,189,190,221,168,175,93,180,215,123,65,66,67,68,69,70,71,72,73,173,244,246,242,243,245,125,74,75,76,77,78,79,80,81,82,185,251,252,249,250,255,92,247,83,84,85,86,87,88,89,90,178,212,214,210,211,213,48,49,50,51,52,53,54,55,56,57,179,219,220,217,218,159
  );

  function convert2Ascii(ebcdicBytes:any, recordLength:any) {
    const result = [];
    let vb = false;
    let startIndex = 0;
    let len = 0;
    let maxRecordLength = 0;

    if (ebcdicBytes.length < 4) {
      return ["May not be a valid file"];
    }
  
    if (recordLength === 0) {
      if (ebcdicBytes[3] !== 0 || ebcdicBytes[2] !== 0) {
        return ["Record length was zero but file was not Variable Block"];
      }
  
      recordLength = getRecordLen(startIndex, ebcdicBytes);
      if (recordLength > 32756 || recordLength < 0) {
        return ["Invalid record length"];
      }
  
      vb = true;
      startIndex = 4;
      len = 3;
    }
  
    const lines = [];
    const asciiBytes = new Uint8Array(ebcdicBytes.length);
  
    for (let i = startIndex; i < ebcdicBytes.length; i++) {
      len++;
      asciiBytes[i] = e2UtfLookup[ebcdicBytes[i]];
  
      if (isUnprintable(ebcdicBytes[i])) {
        asciiBytes[i] = 46;
      }
  
      lines.push(String.fromCharCode(asciiBytes[i]));
  
      if (len === recordLength) {
        result.push(lines.join(""));
        lines.length = 0; 
        len = 0;
  
        if (vb) {
          recordLength = getRecordLen(i, ebcdicBytes);

          if (recordLength > maxRecordLength) {
            maxRecordLength = recordLength;
            localStorage.setItem("maxRecordLength" , ''+ maxRecordLength)
          }
          
          
          if (recordLength <= 0) {
            break;
          }
          i += 3;
          len = 3;
        }
      }
    }
  
    return result;
  }
  function convert2Hexa(ebcdicBytes:any, recordLength:any) {
    const result = [];
    let vb = false;
    let startIndex = 0;
    let len = 0;
  
    if (ebcdicBytes.length < 4) {
      return ["May not be a valid file"];
    }
  
    if (recordLength === 0) {
      if (ebcdicBytes[3] !== 0 || ebcdicBytes[2] !== 0) {
        return ["Record length was zero but file was not Variable Block"];
      }
  
      recordLength = getRecordLen(startIndex, ebcdicBytes);
      if (recordLength > 32756 || recordLength < 0) {
        return ["Invalid record length"];
      }
  
      vb = true;
      startIndex = 4;
      len = 3;
    }
  
    const lines = [];
  
    for (let i = startIndex; i < ebcdicBytes.length; i++) {
      len++;
      const hexValue = ebcdicBytes[i].toString(16).toUpperCase().padStart(2, '0');
  
      lines.push(hexValue);
  
      if (len === recordLength) {
        result.push(lines.join(" "));
        lines.length = 0;
        len = 0;
  
        if (vb) {
          recordLength = getRecordLen(i, ebcdicBytes);
          if (recordLength <= 0) 
            break;
          i += 3;
          len = 3;
        }
      }
    }
  
    return result;
}
function  getRecordLen(index : any, ebcdicBytes: any){
  var recordLength = 0;
  if ((index+4) <= ebcdicBytes.length) {
     recordLength = ((ebcdicBytes[index] & 0xFF) << 8) | (ebcdicBytes[index+1] & 0xFF);
  }  
  return recordLength;
}
const isUnprintable=(ebcdicByte:any)=> {
  if (
    ebcdicByte < 64 ||
    (ebcdicByte > 64 && ebcdicByte < 74) ||
    (ebcdicByte > 80 && ebcdicByte < 90) ||
    (ebcdicByte > 97 && ebcdicByte < 106) ||
    (ebcdicByte > 111 && ebcdicByte < 121)
  )
    return true;
  else return false;
};

const readCompleteRecordsFromChunk = (
  ebcdicBytes:any,
  recordLength:any,
  startIndex:any,
  vb:any,
  start:any,
  end:any,
  dataSize:any,
  maxChunkSize:any
) => {
  let iterator = 0;
  let en;
  let prevRecordLength = 0;

  while (iterator < ebcdicBytes.length) {
    if (vb) {
      prevRecordLength = recordLength;
      recordLength = getRecordLen(startIndex, ebcdicBytes);
    }

    en = startIndex + recordLength;
    if (en < ebcdicBytes.length && recordLength > 0) {
      iterator += recordLength;
    } else if (en === ebcdicBytes.length && recordLength > 0) {
      dataSize = start;
      dataSize += iterator;
      start = dataSize;
      end = start + maxChunkSize;
      break;
    } else {
      dataSize = start + iterator;
      start = dataSize;
      end = start + maxChunkSize;
      break;
    }
    startIndex = iterator;
  }

  if (recordLength <= 0) {
    start = start - prevRecordLength;
  }

  return [ebcdicBytes.slice(0, iterator + recordLength), dataSize, start, end];
};

export {readCompleteRecordsFromChunk}
export {convert2Ascii}
export {convert2Hexa}
export {getRecordLen}
import { useEffect, Suspense, lazy, useState } from "react";
import "./LandingPage.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateComparisonResult, updateDisableNext } from "../../store/Store";
import { Button,Spin,Steps } from "antd";
import FileType from "../fileType/FileType";
import FileUpload from "../../fileUpload/fileUpload";
import AddConfg from "../addConfg/AddConfg";
import ComparisonResult from "../compareResult/ComparisionResult";
import { v4 as uuidv4 } from 'uuid';
import React from "react";

// const ComparisonResult = lazy(() => import("../compareResult/ComparisionResult"));
const LandingPage = () => {
  
  const [currentStep, setCurrentStep] = useState(0);
  const javaFileContent = useSelector((state:any)=>state.javaFileContent);
  const disableNext = useSelector((state:any)=>state.disableNext);
  const dispatch = useDispatch();  
  const [sessionId, setSessionId] = useState("");
  const comparisonResult = useSelector((state:any)=>state.comparisonResult) 
  const [selectedFileNames, setSelectedFileNames] = useState({
    javaOutputFileName: "",
    mainframeOutputFileName: "",
  });
  // const [selectedFiles, setSelectedFiles] = useState({
  //   javaOutputFile: null,
  //   mainframeOutputFile: null,
  // });
  const handleStepClick = (value:number) => {
    if(value<currentStep)
      setCurrentStep(value);
  }
  const isButtonEnabled = useSelector((state:any)=>state.buttonEnabled)
  const steps = [
    {
      title: 'Select File Type',
      content: (
        <FileType />
      ),
    },
    {
      title: 'Select Files',
      content: (
        <FileUpload />
      ),
    },
    {
      title: 'Additional Configurations',
      content: (
       <AddConfg />
      ),
    }
  ];

  useEffect(() => {
    // Generate a new session ID when the component mounts
    const newSessionId = uuidv4();
    setSessionId(newSessionId);
  }, []);

  useEffect(() => {
    const storedJavaFileName = localStorage.getItem("javaFileName") || "";
    const storedMainframeFileName =
      localStorage.getItem("mainframeFileName") || "";

    setSelectedFileNames({
      javaOutputFileName: storedJavaFileName,
      mainframeOutputFileName: storedMainframeFileName,
    });

    const handleUnload = () => {
      localStorage.removeItem("javaFileName");
      localStorage.removeItem("mainframeFileName");
    };
    window.addEventListener("unload", handleUnload);
    return () => {
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  const clearFileNamesFromLocalStorage = () => {
    localStorage.removeItem("javaFileName");
    localStorage.removeItem("mainframeFileName");
  };

  useEffect(() => {
    if (currentStep === 0) {
      clearFileNamesFromLocalStorage();
    }
  }, [currentStep]);

  // useEffect(() => {
  //   if (selectedFiles.javaOutputFile) {
  //     const javaContentKey = "javaContent";
  //     const javaContentValue = selectedFiles.javaOutputFile;
  //     sessionStorage.setItem(javaContentKey, javaContentValue);
  //   }

  //   if (selectedFiles.mainframeOutputFile) {
  //     const mainframeContentKey = "mainframeContent";
  //     const mainframeContentValue = selectedFiles.mainframeOutputFile;
  //     sessionStorage.setItem(mainframeContentKey, mainframeContentValue);
  //   }

  //   const handleUnload = () => {
  //     sessionStorage.removeItem("javaContent");
  //     sessionStorage.removeItem("mainframeContent");
  //   };
  
  //   window.addEventListener("beforeunload", handleUnload);
  
  //   return () => {
  //     window.removeEventListener("beforeunload", handleUnload);
  //   };
    
  // }, [selectedFiles.javaOutputFile, selectedFiles.mainframeOutputFile]);
  
  const next = () => {
    setCurrentStep(currentStep + 1);
    dispatch(updateDisableNext(true))
    if(currentStep===1)
      dispatch(updateDisableNext(false))
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
    dispatch(updateDisableNext(false))
  };
  const itemsStep = steps.map((item) => ({ key: item.title, title: item.title }));
  return (
    <div className="container">
      <Spin spinning={comparisonResult}  size="large">
      <Steps current={currentStep} items={itemsStep} style={{padding:"20px"}} onChange={handleStepClick}/>
      <div className="sub-container">
        <div className="steps-content">{steps[currentStep].content}</div>
        <div className="steps-action">
          {currentStep !==2 && currentStep < steps.length - 1  &&  (
            <Button type="primary" onClick={next} style={{marginTop:"40px", height:"34px"}} disabled={disableNext}>
              Next
            </Button>
          )}
          {currentStep > 0 && (
            <Button style={{ margin: '0 8px', marginTop:"40px", height:"34px" }} onClick={prev}>
              Previous
            </Button>
          )}
        </div>
          <br />
          <br />
        </div>   
        {currentStep === 2 && isButtonEnabled && (
          <div className="compare-container">
            {/* <Suspense fallback={<Spin size="large" tip="Loading the result.." style={{ position: "absolute", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center" }} />}> */}
            <ComparisonResult />
          </div>       
          
        )}
          
          </Spin>
      </div>
  );
};

export default LandingPage;
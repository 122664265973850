import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import './offset.css'

const Offset = () => {
  // var offsetWidth : any;
  const [offsetWidth, setOffsetWidth] = useState(20);
  const recordLength = useSelector((state:any)=>state.recordLength)
  const maxRecordLength = useSelector((state:any)=>state.maxRecordLength)
  const offset = useSelector((state:any)=>state.offset)
  const fileType = useSelector((state:any)=>state.fileType)
  // console.log(maxRecordLength)
  var rLength : any;
  const length = localStorage.getItem("maxRecordLength")
  console.log(length)
  if(length) {
  rLength = parseInt(length,10)
  }
  useEffect(() => {
    if (fileType === "Fixed Length") {
    const newOffsetWidth = Math.ceil(recordLength / 10);
    setOffsetWidth(newOffsetWidth > 0 ? newOffsetWidth : 1);
    }
  }, [recordLength]);

  
  useEffect(() => {
    if (fileType === "Variable Length") {
    const newOffsetWidth = Math.ceil(rLength / 10);
    setOffsetWidth(newOffsetWidth > 0 ? newOffsetWidth : 1);
    } 
  }, [rLength])  
 

  return (
    <div className="col_index" style={{marginLeft: "159px"}}>
      <div className="right_index" style={{ display: "flex", flexDirection: "row"}}>
        {Array.from({ length: offsetWidth }).map((_, i) => (
          <div className="indexWrapper" key={i}>
            <div className="index_head_num" style={{ textAlign: "center"}}> {i * 10}</div>
            <div className="index_data_num">0123456789</div>
          </div>
        ))}
      </div>
    </div>
  );
};


export default Offset;
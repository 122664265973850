import { ILayoutView } from "../../types";
import { Footer } from "../footer";
import { Header } from "../header";

const LayoutView = (props: ILayoutView) => {
  const { children } = props;

  return (
    <div className="flex flex-col min-h-[100vh]">
      <Header />
      <div style={{ flexGrow: 1 }}>{children}</div>
      <Footer />
    </div>
  );
};

export { LayoutView };
export const updateJavaContent = (javaFileContent: string) => {
  return {
    type: "UPDATE_JAVA_CONTENT",
    payload: javaFileContent,
  };
};
export const updateMainFrameContent = (MainFrameFileContent: string) => {
  return {
    type: "UPDATE_MAINFRAME_CONTENT",
    payload: MainFrameFileContent,
  };
};
export const updateJavaFileName = (javaFileName: string) => {
  return {
    type: "UPDATE_JAVA_FILE_NAME",
    payload: javaFileName,
  };
};

export const updateMainFrameFileName = (mainframeFileName: string) => {
  return {
    type: "UPDATE_MAINFRAME_FILE_NAME",
    payload: mainframeFileName,
  };
};
export const updateFileType = (fileType: string) => {
  return {
    type: "UPDATE_FILETYPE",
    payload: fileType,
  };
};
export const updateDisableNext = (disableNext: boolean) => {
  return {
    type: "UPDATE_DISABLE_NEXT",
    payload: disableNext,
  };
};
export const updateRecordLength = (recordLength: number) => {
  return {
    type: "UPDATE_RECORD_LENGTH",
    payload: recordLength,
  };
};
export const updateMaxRecordLength = (maxRecordLength : number) => {
  return {
    type : "UPDATE_MAX_RECORD_LENGTH",
    payload: maxRecordLength,
  }
}
export const updateButtonEnable = (enableButton: boolean) => {
  return {
    type: "UPDATE_BUTTON_ENABLED",
    payload: enableButton,
  };
};
export const updateComparisonResult = (comparisonResult: boolean) => {
  return {
    type: "UPDATE_COMPARISON_RESULT",
    payload: comparisonResult,
  };
};
export const updateDiffOnly = (diffOnly: boolean) => {
  return {
    type: "UPDATE_DIFF_ONLY",
    payload: diffOnly,
  };
};
export const updateSplitView = (splitView: boolean) => {
  return {
    type: "UPDATE_SPLIT_VIEW",
    payload: splitView,
  };
};
export const updateContextCheck = (contextCheck: boolean) => {
  return {
    type: "UPDATE_CONTEXT_CHECK",
    payload: contextCheck,
  };
};
export const updateContext = (context: number) => {
  return {
    type: "UPDATE_CONTEXT",
    payload: context,
  };
};
export const updateLinenNumber = (lineNumberClicked: number) => {
  return {
    type: "UPDATE_LINE",
    payload: lineNumberClicked,
  };
};

export const updateCFResult = (CFResult : any) => {
  return {
    type : "UPDATE_CFRESULT",
    payload : CFResult,
  }
}

export const updateMFResult = (MFResult : any) => {
  return {
    type : "UPDATE_MFRESULT",
    payload : MFResult,
  }
}
export const updateAsciiHexCFResult = (asciiHexCF : any) => {
  return {
    type : "UPDATE_ASCIIHEXCFRESULT",
    payload : asciiHexCF,
  }
}
export const updateAsciiHexMFResult = (asciiHexMF : any) => {
  return {
    type : "UPDATE_ASCIIHEXMFRESULT",
    payload : asciiHexMF,
  }
}
export const updateOffestView = (offset : boolean) => {
  return {
    type : "UPDATE_OFFSET_VIEW",
    payload : offset,
  }
}
const initialState = {
  javaFileContent: "",
  mainFrameFileContent: "",
  javaFileName: "",
  mainframeFileName: "",
  fileType: "Fixed Length",
  disableNext: true,
  recordLength: "",
  maxRecordLength: "",
  buttonEnabled: false,
  comparisonResult: false,
  diffOnly: true,
  splitView: true,
  context: 0,
  contextcheck : false,
  offset : false,
  CFResult : [],
  MFResult : [],
  asciiHexCF : {},
  asciiHexMF : {},
}
const rootReducer = (state = initialState, action:any) => {
      switch(action.type) {
          case "UPDATE_JAVA_CONTENT" :
              return {
                  ...state,
                  javaFileContent : action.payload,
              }
          case "UPDATE_MAINFRAME_CONTENT" :
              return {
                  ...state,
                  mainFrameFileContent : action.payload,
              }
          case "UPDATE_JAVA_FILE_NAME" :
              return {
                ...state,
                javaFileName : action.payload,
              }
          case "UPDATE_MAINFRAME_FILE_NAME" :
              return {
                  ...state,
                  mainframeFileName : action.payload,
                }
          case "UPDATE_FILETYPE" :
                  return {
                      ...state,
                      fileType : action.payload,
                  }
          case "UPDATE_DISABLE_NEXT" :
                  return {
                      ...state,
                      disableNext : action.payload,
                  }
          case "UPDATE_RECORD_LENGTH" :
                  return {
                      ...state,
                      recordLength : action.payload,
                  }
          case "UPDATE_MAX_RECORD_LENGTH" :
                  return {
                      ...state,
                      maxRecordLength: action.payload,
                  }
          case "UPDATE_BUTTON_ENABLED" :
                  return {
                      ...state,
                      buttonEnabled : action.payload,
                  }
          case "UPDATE_COMPARISON_RESULT" :
                  return {
                      ...state,
                      comparisonResult : action.payload,
                  }
          case "UPDATE_DIFF_ONLY":
                  return {
                      ...state,
                      diffOnly : action.payload,
                  } 
          case "UPDATE_SPLIT_VIEW" :
              return {
                  ...state,
                  splitView : action.payload,
              }  
          case "UPDATE_CONTEXT" :
            return {
              ...state,
              context: action.payload,
            } 
          case "UPDATE_CONTEXT_CHECK" :
            return {
              ...state,
              contextcheck : action.payload
            }
          case "UPDATE_OFFSET_VIEW" :   
            return {
              ...state,
              offset : action.payload
            }         
          case "UPDATE_CFRESULT" :
            return {
              ...state,
              CFResult : action.payload,
            }  
          case "UPDATE_MFRESULT" :
            return {
              ...state,
              MFResult : action.payload,
            } 
          case "UPDATE_ASCIIHEXCFRESULT" :
            return {
              ...state,
              asciiHexCF : action.payload,
            } 
          case "UPDATE_ASCIIHEXMFRESULT" :
            return {
              ...state,
              asciiHexMF : action.payload,
            }                            
          default:
                  return state
      }
}
export default rootReducer
import { useDispatch, useSelector } from "react-redux";
import {
  convert2Ascii,
  convert2Hexa,
  getRecordLen,
  readCompleteRecordsFromChunk,
} from "../components/conversion/convert2Ascii";
import { useEffect, useState } from "react";
import {
  updateAsciiHexCFResult,
  updateAsciiHexMFResult,
  updateButtonEnable,
  updateCFResult,
  updateDisableNext,
  updateJavaContent,
  updateJavaFileName,
  updateMFResult,
  updateMainFrameContent,
  updateMainFrameFileName,
} from "../store/Store";
import { Spin } from "antd";
type AsciiToHexMap = {
  [key: string]: string;
};
const FileUpload: React.FC = () => {
  var recordLength = useSelector((state: any) => state.recordLength);
  const fileType = useSelector((state: any) => state.fileType);
  const [javaUploaded, setJavaUploaded] = useState(false);
  const [mfUploaded, setMFUploaded] = useState(false);
  const [selectedFileNames, setSelectedFileNames] = useState({
    javaOutputFileName: "",
    mainframeOutputFileName: "",
  });
  let chunkSize = 3 * 1024 * 1024;
  let startIndex = 0;
  let vb = false;
  let dataSize = 0;
  let start = 0;
  let end = start + chunkSize;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (javaUploaded && mfUploaded) {
      dispatch(updateButtonEnable(true));
      dispatch(updateDisableNext(false));
    }
  }, [dispatch, javaUploaded, mfUploaded]);
  const [selectedFiles, setSelectedFiles] = useState({
    javaOutputFile: null,
    mainframeOutputFile: null,
  });
  const [fileUploadStatus, setFileUploadStatus] = useState({
    javaOutputFileUploaded: false,
    mainframeOutputFileUploaded: false,
  });
  const handleFileSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    fileType: string
  ) => {
    setSelectedFiles({
      ...selectedFiles,
      [fileType]: event.target.files ? event.target.files[0] : null,
    });
  };
  function convertEBCDICToASCIIAndSetContent(file: any, type: string) {
    let fileData = new Blob([file]);
    var promise = new Promise(getBuffer(fileData));
    promise
      .then(function (data) {
        if (fileType === "Variable Length") {
          recordLength = 0;
          vb = true;
        }
        var result = convert2Ascii(data, recordLength);
        var resultAscii = convert2Ascii(data, recordLength);
        // console.log(resultAscii[1])
        var resultHex = convert2Hexa(data,recordLength)
        var asciiToHexMap:any = new Map();
        for (var j = 0; j < Math.max(result.length, resultHex.length); j++) {
          asciiToHexMap.set(resultAscii[j], resultHex[j]);
        }
        if(type==="MainFrame"){
          dispatch(updateMFResult(result)) 
          dispatch(updateAsciiHexMFResult(asciiToHexMap))
        } else{
          dispatch(updateCFResult(result)) 
          dispatch(updateAsciiHexCFResult(asciiToHexMap))
        } 
        let output = "";
        for (var j = 0; j < result.length - 1; j++) {
          output += result[j] + "\n";
        }
        output += result[j];
        if (type === "MainFrame") {
          dispatch(updateMainFrameContent(output));
        } else {
          dispatch(updateJavaContent(output));
        }
        // }
      })
      .catch(function (err) {
        console.log("Error: ", err);
      });
    function getBuffer(fileData: any) {
      return function (resolve: any) {
        var reader = new FileReader();
        reader.readAsArrayBuffer(fileData);
        reader.onload = function () {
          var arrayBuffer = reader.result;
          var bytes = new Uint8Array(arrayBuffer as any);
          resolve(bytes);
        };
      };
    }
  }
  const handleJavaFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    // setLoading(true)
    if (file) {
      setSelectedFileNames({
        ...selectedFileNames,
        javaOutputFileName: file.name,
      });
      const reader = new FileReader();
      reader.onloadstart = () => {
        setLoading(true);
      };
      reader.onload = (e) => {
        if (e.target) {
          const content = e.target.result as ArrayBuffer;
          convertEBCDICToASCIIAndSetContent(content, "Java");

          handleFileSelect(event, "javaOutputFile");
          const javaFileName = file.name;
          localStorage.setItem("javaFileName", javaFileName);
          dispatch(updateJavaFileName(javaFileName));
          setLoading(false);
        }
      };
      reader.readAsArrayBuffer(file);
      setJavaUploaded(true);
      setFileUploadStatus({
        ...fileUploadStatus,
        javaOutputFileUploaded: true,
      });
    }
  };
  const handleMainframeFileSelect = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    setLoading(true);
    if (file) {
      setSelectedFileNames({
        ...selectedFileNames,
        mainframeOutputFileName: file.name,
      });
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target) {
          const content = e.target.result as ArrayBuffer;
          convertEBCDICToASCIIAndSetContent(content, "MainFrame");
          setLoading(false);
          handleFileSelect(event, "mainframeOutputFile");
          const mainframeFileName = file.name;
          localStorage.setItem("mainframeFileName", mainframeFileName);
          dispatch(updateMainFrameFileName(mainframeFileName));
        }
      };
      reader.readAsArrayBuffer(file);
      setMFUploaded(true);
      setFileUploadStatus({
        ...fileUploadStatus,
        mainframeOutputFileUploaded: true,
      });
    }
  };

  useEffect(() => {
    const storedJavaFileName = localStorage.getItem("javaFileName") || "";
    const storedMainframeFileName =localStorage.getItem("mainframeFileName") || "";

    setSelectedFileNames({
      javaOutputFileName: storedJavaFileName,
      mainframeOutputFileName: storedMainframeFileName,
    });

    const handleUnload = () => {
      localStorage.removeItem("javaFileName");
      localStorage.removeItem("mainframeFileName");
    };

    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  // useEffect(() => {
  //   if (selectedFiles.javaOutputFile) {
  //     const javaContentKey = "javaContent";
  //     const javaContentValue = selectedFiles.javaOutputFile;
  //     sessionStorage.setItem(javaContentKey, javaContentValue);
  //   }

  //   if (selectedFiles.mainframeOutputFile) {
  //     const mainframeContentKey = "mainframeContent";
  //     const mainframeContentValue = selectedFiles.mainframeOutputFile;
  //     sessionStorage.setItem(mainframeContentKey, mainframeContentValue);
  //   }

  //   const handleUnload = () => {
  //     sessionStorage.removeItem("javaContent");
  //     sessionStorage.removeItem("mainframeContent");
  //   };
  
  //   window.addEventListener("beforeunload", handleUnload);
  
  //   return () => {
  //     window.removeEventListener("beforeunload", handleUnload);
  //   };
    
  // }, [selectedFiles.javaOutputFile, selectedFiles.mainframeOutputFile]);

  return (
    <div>
      <Spin tip="Uploading the file" size="large" spinning={loading}>
        <h2 className="main-heading">Select the Required Files</h2>
        <div className="file-section">
          <div className="column">
            <h3 className="file-heading">Select Java Output File</h3>
            <label className="file-label" htmlFor="file2">
              <input
                type="file"
                className="file_input"
                id="file2"
                onChange={handleJavaFileSelect}
              />
              <span>Choose Java File</span>
            </label>
            <div className="file-display">
              <p>{selectedFileNames.javaOutputFileName}</p>
            </div>
            {fileUploadStatus.javaOutputFileUploaded &&
              selectedFiles.javaOutputFile && (
                <p className="upload-status-text">File Selected!</p>
              )}
          </div>
          <div className="column">
            <h3 className="file-heading">Select Mainframe Output File</h3>
            <label className="file-label" htmlFor="file3">
              <input
                type="file"
                className="file_input"
                id="file3"
                onChange={handleMainframeFileSelect}
              />
              <span>Choose Mainframe File</span>
            </label>
            <div className="file-display">
              <p>{selectedFileNames.mainframeOutputFileName}</p>
            </div>
            {fileUploadStatus.mainframeOutputFileUploaded &&
              selectedFiles.mainframeOutputFile && (
                <p className="upload-status-text">File Selected!</p>
              )}
          </div>
        </div>
      </Spin>
    </div>
  );
};
export default FileUpload;
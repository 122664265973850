import './App.css';

import { LayoutView } from './components/layout-view';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LandingPage } from './components/landing-page'
import ComparisonResult from './components/compareResult/ComparisionResult';

function App() {
  return (
    <BrowserRouter>
    <LayoutView>
    <main>
      <Routes>
      <Route path="/" Component={LandingPage} />
      <Route path="/result" Component={ComparisonResult} />
    </Routes>
    </main>
  </LayoutView>
  </BrowserRouter>
  );
}

export default App;